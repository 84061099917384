import { ref } from 'vue';
import throttle from '@ice-products-ui/vue-library/Throttle';
import useDisableScrollbar from '~/composables/dom/useDisableScrollbar';

/**
 * @description Dropbox molecule composable
 * @returns {{disableState: Ref<UnwrapRef<boolean>>, toggleBodyScroll: () => void, dropboxState: Ref<UnwrapRef<boolean>>}}
 */
export default function useDropbox() {
  const dropboxState = ref<boolean>(false);
  const disableState = ref<boolean>(false);
  const { compensateScrollbar, deCompensateScrollbar } = useDisableScrollbar();

  const disabledHandler = (): void => {
    disableState.value = window.matchMedia(`(max-width: 1200px)`).matches;
  };

  function toggleBodyScroll() {
    disableState.value && dropboxState.value ? compensateScrollbar() : deCompensateScrollbar();
  }

  const disabledHandlerLink = throttle(disabledHandler, 500);

  onMounted(() => {
    disabledHandler();
    window.addEventListener('resize', disabledHandlerLink);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', disabledHandlerLink);
  });

  return {
    dropboxState,
    disableState,
    toggleBodyScroll,
  };
}
